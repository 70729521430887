//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class Visits {
 
  list(data){
    return http.get( api.visits.get.endpoint, data);
  }

  create(params){
    return http.post( api.visits.create.endpoint, params );
  }
  
  update(data,params){
    return http.put( api.visits.update.endpoint+params, data );
  }
  
  delete(data){
    return http.delete( api.visits.delete.endpoint+data );
  }

}

export default new Visits();