// import axios from 'axios';
import http from '../../axios/axios_conf'
import api from '../../routers/routers'

class departaments {
  list(params) {
    return http.get(api.departments.get.endpoint, params)
  }

  create(departament_data) {
    return http.post(api.departments.create.endpoint, departament_data)
  }

  update(departament_uuid, departament_data) {
    return http.put(
      api.departments.update.endpoint + departament_uuid,
      departament_data,
    )
  }

  delete(Building_uuid) {
    return http.delete(api.departments.update.endpoint + Building_uuid)
  }

  payment_template() {
    return http.get(api.departments.payment_template.endpoint, {
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Origin': '*', 
      }
    })
  }
  payment_template_user() {
    return http.get(api.departments.payment_template_user.endpoint, {
      responseType: 'blob',
      headers: {
        'Access-Control-Allow-Origin': '*', 
      }
    })
  }

  upload_excel(departament_data) {
    return http.post(api.departments.upload_excel.endpoint, departament_data, {
      headers: {
        'Content-Type': 'multipart/form-data',
         Accept: 'application/json',
        'Access-Control-Allow-Origin': '*', 
      }
    })
  }

  upload_excel_user(departament_data) {
    return http.post(api.departments.upload_excel_user.endpoint, departament_data, {
      headers: {
        'Content-Type': 'multipart/form-data',
         Accept: 'application/json',
        'Access-Control-Allow-Origin': '*', 
      }
    })
  }
}

export default new departaments()
