<template>
  <div class="">
    <b-card
      class="border-0 p-2"
      body-class="hp-knowledge-basic-card text-center"
    >
      <b-row v-if="showFilter">
        <b-col cols="7" class="my-3">
          <b-form-group
            label="Búsqueda"
            label-for="filter-input"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :debounce="300"
                placeholder="Escribe para iniciar la búsqueda"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  variant="outline-info-3"
                  :disabled="!filter"
                  @click="filter = ''"
                  >Limpiar</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <slot name="filters"></slot>
      </b-row>

      <!-- Main table element -->
      <b-table
        ref="table"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        empty-filtered-text="Sin resultados en la búsqueda"
        empty-text="Sin resultados para mostrar"
        @filtered="onFiltered"
        class="mt-24"
      >
        <template #cell(info_payemnt_data)="{ item }">
          <label for="created_by" class="created_by">Creado por: </label>
          <span class="raw_html_custom"
            ><i class="iconly-Curved-Paper"> </i>
            {{ item.created_by.name + " " + item.created_by.last_name }}</span
          >
          <label for="created_by" class="created_by">Descripción: </label>
          <span class="raw_html_custom"
            ><i class="iconly-Curved-PaperUpload"> </i>
            {{
              item.description != null
                ? item.description
                : "N/A"
            }}</span
          >
          <label for="created_by" class="created_by"
            >Numero de departamento:
          </label>
          <span class="raw_html_custom"
            ><i class="iconly-Broken-Wallet"> </i>
            {{
              item.department_uuid != null ? item.department_uuid.nro : "N/A"
            }}</span
          >
        </template>
        <template #cell(info_payemnt_reportado)="{ item }">
          <label for="created_by" class="created_by">Reportado por: </label>
          <span class="raw_html_custom"
            ><i class="iconly-Curved-Paper"> </i>
            {{
              item.reported_by != null
                ? item.reported_by.name + " " + item.reported_by.last_name
                : "N/A"
            }}</span
          >
          <label for="created_by" class="created_by"
            >Numero de transferencia:
          </label>
          <span class="raw_html_custom"
            ><i class="iconly-Broken-Wallet"> </i>
            {{
              item.reported_number != null ? item.reported_number : "N/A"
            }}</span
          >
          <span
            v-if="item.reported_image != null"
            class="raw_html_custom download_img"
            ><a
              :href="
                item.reported_image != null ? item.reported_image.path : '#'
              "
              target="_blank"
              >Descargar Imagen</a
            ></span
          >
        </template>
        <template #cell(info_payemnt_aprobado)="{ item }">
          <label for="created_by" class="created_by">Aprobado por: </label>
          <span class="raw_html_custom"
            ><i class="iconly-Curved-Paper"> </i>
            {{
              item.approved_by != null
                ? item.approved_by.name + " " + item.approved_by.last_name
                : "N/A"
            }}</span
          >
        </template>
        <template #cell(rejected)="{ item }">
          <slot name="rejected" :item="item"></slot>
        </template>
        <template #cell(pending_replacement)="{ item }">
          <slot name="pending_replacement" :item="item"></slot>
        </template>
        <template #cell(replaced)="{ item }">
          <slot name="replaced" :item="item"></slot>
        </template>
        <template #cell(status)="{ item }">
          <slot name="status" :item="item"></slot>
        </template>
        <template #cell(actions)="{ item }">
          <slot name="actions" :item="item"></slot>
        </template>
        <template #cell(image)="{ item }">
          <slot name="image" :item="item"></slot>
        </template>

        <template #cell(name)="{ item }">
          <slot name="name" :item="item"></slot>
        </template>
        <template #cell(checklist_type_show)="{ item }">
          <slot name="checklist_type_show" :item="item"></slot>
        </template>
      </b-table>

      <b-row
        class="mt-32 align-items-center justify-content-end"
        v-if="showFilter"
      >
        <b-col class="hp-flex-none w-auto">
          <b-form-group
            label-for="per-page-select"
            label-size="sm"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              @change="$emit('changePerPage', perPage)"
            />
          </b-form-group>
        </b-col>

        <b-col class="hp-flex-none w-auto">
          <template>
            <slot name="pagination"></slot>
          </template>
          <!-- <b-pagination
            align="end"
            v-model="currentPage"
            :total-rows="16"
            :per-page="perPage"
          ></b-pagination> -->
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormCheckboxGroup,
  BPagination,
  BCard,
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormCheckbox,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormCheckboxGroup,
    BPagination,
    BCard,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    totalRows_: {
      type: Number,
      default: 1,
    },
    currentPage_: {
      type: Number,
      default: 1,
    },
    perPage_: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, 50],
      sortBy: "",
      sortDesc: false,
      sortDirection: "desc",
      filter: null,
      filterOn: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  watch: {
    items(newValue) {
      this.totalRows = this.items.length;
    },

    totalRows_(newValue) {
      this.totalRows = this.totalRows_;
    },
    currentPage_(newValue) {
      this.currentPage = this.currentPage_;
    },
    perPage_(newValue) {
      console.log(newValue);
      this.perPage = this.perPage_;
    },
  },
};
</script>
