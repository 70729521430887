//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class dni {
  
  list() {
    return http.get( api.dni_types.get.endpoint );
  }
 

}

export default new dni();